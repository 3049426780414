import * as React from "react"
import {Link} from "gatsby"
import $ from 'jquery'

import Layout from "../components/layout"
import Seo from "../components/seo"
import {Component} from "react";

export class ResponsibleDisclosure extends Component {
    componentDidMount() {
        $(document).ready(function () {
            $('a[href*=\\#]').bind('click', function (e) {
                var target = $(this).attr("href");
                $('html, body').stop().animate({
                    scrollTop: $(target).offset().top + 10
                }, 600, function () {
                    window.location.hash = target;
                });
                return false;
            });
        });

        $(window).scroll(function () {
            var scrollDistance = $(window).scrollTop();

            $('.content-section').each(function (i) {
                if ($(this).position().top - 10 <= scrollDistance) {
                    $('.nav-helper a.active-side').removeClass('active-side');
                    $('.nav-helper a').eq(i).addClass('active-side');
                }
            });
        }).scroll();


    }

    render() {
        return (
            <Layout>
                <div className="content">
                    <div className="content-body">
                        <div className="content-section" id="responsible-disclosure">
                            <h1>Responsible Disclosure</h1>
                            <br/>
                            <h2>Notice:</h2>
                            <p className={"display-reset"}>
                                We have decided to{" "}
                                <span className="text-red">temporarily suspend the bug bounty program</span>{" "}
                                to catch up on the backlog of reports and prioritize other
                                improvements on our roadmap. We will update this message when
                                the bounty program is open to new submissions again.
                            </p>

                            <h2>Introduction:</h2>
                            <p>
                                At the Hyperstack Corporation, we consider the security of our
                                systems a top priority. But no matter how much effort we put into
                                system security, there can still be vulnerabilities present.
                            </p>

                            <p>
                                If you discover a vulnerability, we would like to know about it so
                                we can take steps to address it as quickly as possible. We would
                                like to ask you to help us better protect our clients and our
                                systems.
                            </p>
                        </div>
                        <div className="content-section" id="please-do-the-following">

                            <h2>Please do the following:</h2>
                            <p>

                                <ul className="page-list">
                                    <li>E-mail your findings to hello@thehyperstack.com</li>
                                    <li>
                                        Do not take advantage of the vulnerability or problem you have
                                        discovered, for example by downloading more data than
                                        necessary to demonstrate the vulnerability or deleting or
                                        modifying other people's data,
                                    </li>
                                    <li>
                                        Do not reveal the problem to others until it has been
                                        resolved,
                                    </li>
                                    <li>
                                        Do not use attacks on physical security, social engineering,
                                        distributed denial of service, spam or applications of third
                                        parties, and
                                    </li>
                                    <li>
                                        Do provide sufficient information to reproduce the problem, so
                                        we will be able to resolve it as quickly as possible. Usually,
                                        the IP address or the URL of the affected system and a
                                        description of the vulnerability will be sufficient, but
                                        complex vulnerabilities may require further explanation.
                                    </li>
                                </ul>
                            </p>
                        </div>
                        <div className="content-section" id="what-we-promise">

                            <h2>What we promise:</h2>

                            <ul className="page-list">
                                <li>
                                    We will respond to your report within 3 business days with our
                                    evaluation of the report and an expected resolution date,
                                </li>
                                <li>
                                    If you have followed the instructions above, we will not take
                                    any legal action against you in regard to the report,
                                </li>
                                <li>
                                    We will handle your report with strict confidentiality, and
                                    not pass on your personal details to third parties without
                                    your permission,
                                </li>
                                <li>
                                    We will keep you informed of the progress towards resolving
                                    the problem,
                                </li>
                                <li>
                                    In the public information concerning the problem reported, we
                                    will give your name as the discoverer of the problem (unless
                                    you desire otherwise), and
                                </li>
                                <li>
                                    As a token of our gratitude for your assistance, Critical &
                                    High severity valid bug reporters will be listed on
                                    Hyperstack's wall of Fame. Of course, we will need to know if
                                    you want the recognition, in which case you will be required
                                    to give us your name and Twitter handle, LinkedIn Profile as
                                    you wish it to be displayed on our Hall of Fame page.
                                </li>
                                <li>
                                    We currently do not offer any monetary compensation. However,
                                    we may send out Hyperstack swag in some cases.
                                </li>
                                <li>
                                    Requests or demands for monetary compensation in connection
                                    with any identified or alleged vulnerability are non-compliant
                                    with this Responsible Disclosure Policy.
                                    {/* Currently monetary rewarding is on hold.  */}
                                    {/* we offer a reward for every report of a security problem that was not yet
                  known to us. The amount of the reward will be determined based
                  on the severity of the leak and the quality of the report. */}
                                </li>
                            </ul>

                        </div>
                        <div className="content-section" id="valid-vulnerability-categories">
                            <h2>Valid Vulnerability Categories</h2>
                            <p>
                                Automated tools or scripts ARE STRICTLY PROHIBITED, and any POC
                                submitted to us should have a proper step-by-step guide to
                                reproduce the issue. Abuse of any vulnerability found shall be
                                liable for legal penalties
                            </p>

                            <ul>
                                <li>
                                    1. Cross-Site Request Forgery ** - With significant security
                                    impact
                                </li>
                                <li>2. Cross-Site Scripting ** Self-XSS is out of scope</li>
                                <li>3. Open Redirects ** With significant security impact</li>
                                <li>
                                    4. Cross Origin Resource Sharing ** With significant security
                                    impact
                                </li>
                                <li>5. SQL injections</li>
                                <li>6. Server Side Request Forgery</li>
                                <li>7. Privilege Escalation</li>
                                <li>8. Local File Inclusion</li>
                                <li>9. Remote File Inclusion</li>
                                <li>10. Leakage of Sensitive Data</li>
                                <li>11. Authentication Bypass</li>
                                <li>12. Directory Traversal</li>
                                <li>13. Payment Manipulation</li>
                                <li>14. Remote Code Execution</li>
                            </ul>
                        </div>
                        <div className="content-section" id="in-scope-domain">
                            <h2>In Scope Domain</h2>
                            <p>studio.thehyperstack.com</p>
                        </div>
                        <div className="content-section" id="out-of-scope">
                            <h2>Out of Scope</h2>
                            <p>
                                <ul className="page-list">
                                    <li>Price manipulation WITHOUT SUCCESSFUL TRANSACTION</li>
                                    <li>
                                        Any services hosted by 3rd party providers and services not
                                        provided by Hyperstack
                                    </li>
                                    <li>
                                        Any service that is not mentioned in the In Scope domains
                                        section
                                    </li>
                                    <li>
                                        IDOR references for objects that you have permission to access
                                    </li>
                                    <li>Duplicate submissions that are being remediated</li>
                                    <li>Known issues</li>

                                    <li>
                                        Multiple reports for the same vulnerability type with minor
                                        differences (only one will be rewarded)
                                    </li>
                                    <li>Open redirects</li>
                                    <li>
                                        Clickjacking and issues only exploitable through clickjacking
                                    </li>
                                    <li>
                                        Only session cookies needed http and secure flags. Apart from
                                        these, for other cookies we won’t consider as vulnerability
                                    </li>
                                    <li>
                                        Issues without clearly identified security impact such as
                                        missing security headers.
                                    </li>
                                    <li>
                                        Vulnerabilities requiring physical access to the victim's
                                        unlocked device.
                                    </li>
                                    <li>Formula Injection or CSV Injection</li>
                                    <li>
                                        DOM Based Self-XSS and issues exploitable only through
                                        Self-XSS.
                                    </li>

                                    <li>
                                        System and Infrastructure Related
                                        <ul className="page-list">

                                            <li>Networking issues or industry standards</li>

                                            <li>
                                                Email related:
                                                <ul className="page-list">
                                                    <li>SPF or DMARC records</li>
                                                    <li>
                                                        Gmail &quot;+&quot; and &quot;.&quot; acceptance
                                                    </li>
                                                    <li>Email bombs</li>
                                                    <li>Unsubscribing from marketing emails</li>
                                                </ul>
                                            </li>

                                            <li>
                                                Information Leakage:
                                                <ul className="page-list">
                                                    <li>
                                                        HTTP 404 codes/pages or other HTTP non-200 codes/pages
                                                    </li>
                                                    <li>
                                                        Fingerprinting / banner disclosure on common/public
                                                        services
                                                    </li>
                                                    <li>
                                                        Disclosure of known public files or directories (e.g.
                                                        robots.txt)
                                                    </li>
                                                </ul>
                                            </li>

                                            <li>Cacheable SSL pages</li>

                                            <li>Login and Session Related</li>
                                            <ul className="page-list">
                                                <li>
                                                    Forgot Password page bruteforce and account lockout not
                                                    enforced
                                                </li>
                                                <li>Lack of Captcha</li>
                                                <li>
                                                    Presence of application or web browser ‘autocomplete’ or
                                                    ‘save password’ functionality
                                                </li>
                                                <li>Session Timeouts</li>
                                            </ul>
                                        </ul>
                                    </li>
                                </ul>
                            </p>
                        </div>
                        <div className="content-section" id="eligibility">
                            <h2>Eligibility</h2>
                            <p>
                                <ul className="page-list">
                                    <li>Be the first to report the issue to us.</li>
                                    <li>
                                        Must pertain to an item explicitly listed under Vulnerability
                                        Categories.
                                    </li>
                                    <li>
                                        Must contain sufficient information including a proof of
                                        concept screenshot, video, or code snippet where needed.
                                    </li>
                                    <li>
                                        You agree to participate in testing the effectiveness of the
                                        countermeasure applied to your report.
                                    </li>
                                    <li>
                                        You agree to keep any communication with Hyperstack private.
                                    </li>
                                </ul>
                            </p>
                        </div>
                        <div className="content-section" id="public-disclosure-policy">
                            <h2>Public Disclosure Policy:</h2>
                            <p>

                                By default, this program is in “PUBLIC NONDISCLOSURE” mode which
                                means: "THIS PROGRAM DOES NOT ALLOW PUBLIC DISCLOSURE. ONE SHOULD
                                NOT RELEASE THE INFORMATION ABOUT VULNERABILITIES FOUND IN THIS
                                PROGRAM TO PUBLIC, FAILING WHICH SHALL BE LIABLE FOR LEGAL
                                PENALTIES!”
                            </p>
                        </div>
                        <div className="content-section" id="conclusion">

                            <h2>Conclusion</h2>

                            <p>
                                We strive to resolve all problems as quickly as possible, and we
                                would like to play an active role in the ultimate publication on
                                the problem after it is resolved.


                                Hyperstack reserves the rights to discontinue the reward program
                                without previous notice at any time. We may modify the terms of
                                this program or terminate this program at any time. We won’t apply
                                any changes we make to these program terms retroactively.
                                Hyperstack employees and their family members are not eligible for
                                bounties.
                            </p>
                        </div>
                        <div className="content-section" id="hall-of-fame">
                            <h2>Bug Bounty Hall of Fame</h2>

                            <p>
                                On behalf of the Hyperstack and the thousands of people who visit
                                our sites, use Hyperstack and our other products we would like to
                                thank them for their hard work in helping to make us more secure.

                                Congratulations to everybody who has participated!
                            </p>


                            <p>1st Quarter 2021</p>


                            <a href="https://www.linkedin.com/in/abhijith-a-559b761aa/">
                                Abhijith A
                            </a>

                        </div>


                    </div>

                    <div className="content-nav">
                        <div className="nav-helper">

                            <a href="#responsible-disclosure" className="nav-helper__link active-header">Responsible
                                Disclosure</a>
                            <a href="#please-do-the-following" className="nav-helper__link">Please do the following</a>
                            <a href="#what-we-promise" className="nav-helper__link">What we promise</a>
                            <a href="#valid-vulnerability-categories" className="nav-helper__link">Valid Vulnerability
                                Categories</a>
                            <a href="#in-scope-domain" className="nav-helper__link">In Scope Domain</a>
                            <a href="#out-of-scope" className="nav-helper__link">Out of Scope</a>
                            <a href="#eligibility" className="nav-helper__link">Eligibility</a>
                            <a href="#public-disclosure-policy" className="nav-helper__link">Public Disclosure
                                Policy</a>
                            <a href="#conclusion" className="nav-helper__link">Conclusion</a>
                            <a href="#hall-of-fame" className="nav-helper__link">Hall of Fame</a>

                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export const Head = () => <Seo
    title="Responsible Disclosure of Hyperstack"
    description="Security of our systems is our top priority"
/>

export default ResponsibleDisclosure
